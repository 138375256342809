<template>
    <section id="pricing-plan">
        <!-- title text and switch button -->
        <h2 class="mt-5">Riepilogo</h2>
        <b-row v-if="pricing.length > 0">
            <b-col md="8">
                <b-card>
                    <b-row>
                        <b-col md="8">
                            <b-card-text><strong>{{ subscription_type.subscription_plan_name }}</strong></b-card-text>
                            <ul>
                                <li>Progetti Nuovi Impianti: {{
                                        newProjectCredit(subscription_type) == 999 ? 'Illimitati' : integerFormatter.format(newProjectCredit(subscription_type))
                                }}</li>
                                <li>Progetti Manutenzione Impianti: {{
                                        maintenanceProjectCredits(subscription_type) == 999 ? 'Illimitati' : integerFormatter.format(maintenanceProjectCredits(subscription_type))
                                }}</li>
                            </ul>
                            <span
                                v-if="subscription_type.plan_type == 'subscription' && subscription_type.subscription_month == '1'">N.B.:
                                I crediti non utilizzati nel mese andranno persi</span>
                            <span
                                v-if="subscription_type.plan_type == 'subscription' && subscription_type.subscription_month == '12'">N.B.:
                                I crediti sono mensili e quelli non utilizzati nel mese andranno persi</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card>
                    <strong>Riepilogo importi</strong>
                    <hr />
                    <div class="d-flex justify-content-between">
                        <span>Prezzo listino</span>
                        <span>{{ priceFormatter.format(price(subscription_type)) }} &euro;</span>
                    </div>
                    <div class="d-flex justify-content-between" v-if="discount(subscription_type) > 0">
                        <span>Sconto</span>
                        <span> {{ discount(subscription_type) }} %</span>
                    </div>
                    <div class="d-flex justify-content-between" v-if="discount(subscription_type) > 0">
                        <span>Prezzo a te riservato</span>
                        <span> {{ priceFormatter.format(finalPrice(subscription_type)) }} &euro;</span>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between">
                        <span><strong>Totale</strong></span>
                        <span><strong> {{ priceFormatter.format(finalPrice(subscription_type)) }} &euro;</strong></span>
                    </div>
                    <b-form class="mt-3 d-flex">
                        <b-button class="flex-grow-1" variant="primary" @click="checkout">Procedi al pagamento
                        </b-button>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
        <form id="payment-nexi" action="" class="d-none" method="POST">

        </form>
    </section>

</template>

<script>
import {
    BRow, BCol, BCard, BCardText, BButton
} from 'bootstrap-vue'

import { Requests } from '@/api/requests';

export default {
    components: {
        BRow, BCol, BCard, BCardText, BButton
    },
    data: function () {
        return {
            subscription_id: null,
            pricing: [],
            priceFormatter: new Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            integerFormatter: new Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
        };
    },
    computed: {
        userData: function () {
            return JSON.parse(localStorage.getItem("user"));
        },
        subscription_type: function () {
            if (this.subscription_id) {
                const subscription = this.pricing.find(sub => sub.id_subscription_plan == this.subscription_id);
                return subscription;
            } else {
                return [];
            }
        }
    },

    created() {
        this.subscription_id = this.$route.query.type;
        if (![2, 3, 4].includes(Number(this.subscription_id))) {
            this.$swal('Attenzione', 'Si è verificato un errore', 'error')
                .then(() => {
                    this.$router.push('/');
                })
        }
        this.getPricing();
    },
    methods: {
        price: function (plan) {
            return Number(plan["price_type_" + this.userData.account_type_code]);
        },
        discount: function (plan) {
            return Number(plan["discount_type_" + this.userData.account_type_code]);
        },
        finalPrice: function (plan) {
            return this.price(plan) * (100 - this.discount(plan)) / 100;
        },
        newProjectCredit: function (plan) {
            return Number(plan["project_limit_type_newimplant_" + this.userData.account_type_code]);
        },
        maintenanceProjectCredits: function (plan) {
            return Number(plan["project_limit_type_mantenance_" + this.userData.account_type_code]);
        },
        async getPricing() {
            const response = await Requests.getLicences();
            this.pricing = response.data;
        },
        async checkout() {
            const fd = new FormData();
            fd.append('id_subscription_type', this.subscription_id);
            try {
                const response = await Requests.checkout(fd);
                const form = document.getElementById('payment-nexi');
                form.setAttribute('action', response.XPAY_REQUEST_URL);
                for (const field of Object.keys(response)) {
                    if (field == 'XPAY_REQUEST_URL') continue;
                    const input = document.createElement('input');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', field);
                    input.setAttribute('value', response[field]);
                    form.appendChild(input);
                }
                form.submit();
            } catch (e) {
                this.$swal('Attenzione', 'Si è verificato un errore, si prega di riprovare', 'error');
            }
        }
    },
}
</script>
